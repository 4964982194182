import './ManVacationReports.css';
import { DatePicker, Select, Table, message, Button } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import Papa from 'papaparse';
import Navbar from './components/Navbar';

const ManVacationReports = () => {
    const [employees, setEmployees] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [companies, setCompanies] = useState([]);
    const { Option } = Select;
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 100;
    const { RangePicker } = DatePicker;
    const storedManagerEmail = localStorage.getItem('managerEmail') || '';
    const [managerEmail] = useState(storedManagerEmail);
    const [dateRange, setDateRange] = useState([null, null]); // State to store selected date range
    const [filterType, setFilterType] = useState('start'); // start or end

    // Fetch companies
    const fetchCompany = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/manager/companies', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setCompanies(data.companies);
            } else {
                message.error(data.error || 'Failed to fetch company');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch company');
        }
    };

    // Fetch employees by company
    const fetchEmployeesByCompany = async (companyId) => {
        setDataLoaded(false);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getEmployeesByCompany/${companyId}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setDataLoaded(true);
                setEmployees(data);
            } else {
                message.error(data.message || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch employees');
        }
    };

    // Fetch all employees for the manager
    const fetchAllEmployees = useCallback(async () => {
        setDataLoaded(false);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getEmployeesByManager/${managerEmail}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setDataLoaded(true);
                setEmployees(data);
            } else {
                message.error(data.message || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch employees');
        }
    }, [managerEmail]);

    // Handle company change
    const handleCompanyChange = (companyId) => {
        if (companyId !== null) {
            fetchEmployeesByCompany(companyId);
            setCompanyId(companyId);
        } else {
            fetchAllEmployees();
            setCompanyId(null);
        }
    };

    useEffect(() => {
        fetchCompany();
        fetchAllEmployees();
    }, [fetchAllEmployees]);

    useEffect(() => {
        const fetchData = async () => {
            if (companyId !== null) {
                await fetchEmployeesByCompany(companyId);
            } else {
                await fetchAllEmployees();
            }
            setDataLoaded(true); // Set data loaded to true after fetching data
        };

        setDataLoaded(false); // Set data loaded to false before fetching data
        fetchData();
    }, [companyId, dateRange, fetchAllEmployees]); // Added dateRange dependency

    // Filter employees based on selected date range and filter type (start or end)
    const filterEmployees = () => {
        const [start, end] = dateRange || [];
        if (!start || !end) return employees;

        return employees.filter(employee => {
            if (!employee.vacations || employee.vacations.length === 0) return false;

            return employee.vacations.some(vacation => {
                const vacationStart = moment(vacation.startDate);
                const vacationEnd = moment(vacation.endDate);

                if (filterType === 'start') {
                    return moment(vacationStart).isBetween(start, end, 'day', '[]');
                }
                return moment(vacationEnd).isBetween(start, end, 'day', '[]');
            });
        });
    };
    

    const handleDateRangeChange = (dates) => {
        setDateRange(dates); // Update the date range state with selected start and end dates
    };

    const getVacationCounts = () => {
        // If no date range is selected, return 0
        if (!dateRange[0] || !dateRange[1]) return { onVacationCount: 0 };

        const [start, end] = dateRange;
        let onVacationCount = 0;

        employees.forEach(employee => {
            // Check if the employee has any vacation overlapping with the selected range
            const isOnVacation = employee.vacations?.some(vacation => {
                const vacationStart = moment(vacation.startDate);
                const vacationEnd = moment(vacation.endDate);
                return (
                    vacationStart.isBetween(start, end, 'days', '[]') ||
                    vacationEnd.isBetween(start, end, 'days', '[]') ||
                    (vacationStart.isBefore(start) && vacationEnd.isAfter(end))
                );
            });

            if (isOnVacation) {
                onVacationCount += 1;
            }
        });

        return { onVacationCount };
    };

    const { onVacationCount } = getVacationCounts();

    const columns = [
        {
            title: 'SN',
            dataIndex: 'serial',
            key: 'serial',
            render: (_, __, index) => index + 1
        },
        {
            title: 'E-CODE',
            dataIndex: 'eCode',
            key: 'eCode'
        },
        {
            title: 'EMPLOYEE NAME',
            dataIndex: 'employeeName',
            key: 'employeeName'
        },
        {
            title: 'START DATE',
            dataIndex: 'vacations',
            key: 'startDate',
            render: (vacations) => {
                if (!vacations || vacations.length === 0) return 'N/A';
                
                const [start, end] = dateRange || [];
                if (!start || !end) {
                    return moment(vacations[0].startDate).format('YYYY-MM-DD');
                }

                const matchingVacation = vacations.find(vacation => {
                    const vacationStart = moment(vacation.startDate);
                    const vacationEnd = moment(vacation.endDate);

                    if (filterType === 'start') {
                        return moment(vacationStart).isBetween(start, end, 'day', '[]');
                    }
                    return moment(vacationEnd).isBetween(start, end, 'day', '[]');
                });

                return matchingVacation ? moment(matchingVacation.startDate).format('YYYY-MM-DD') : 'N/A';
            }
        },
        {
            title: 'END DATE',
            dataIndex: 'vacations',
            key: 'endDate',
            render: (vacations) => {
                if (!vacations || vacations.length === 0) return 'N/A';
                
                const [start, end] = dateRange || [];
                if (!start || !end) {
                    return moment(vacations[0].endDate).format('YYYY-MM-DD');
                }

                const matchingVacation = vacations.find(vacation => {
                    const vacationStart = moment(vacation.startDate);
                    const vacationEnd = moment(vacation.endDate);

                    if (filterType === 'start') {
                        return moment(vacationStart).isBetween(start, end, 'day', '[]');
                    }
                    return moment(vacationEnd).isBetween(start, end, 'day', '[]');
                });

                return matchingVacation ? moment(matchingVacation.endDate).format('YYYY-MM-DD') : 'N/A';
            }
        },
        {
            title: 'DAYS',
            dataIndex: 'vacations',
            key: 'days',
            render: (vacations) => {
                if (!vacations || vacations.length === 0) return 0;
                
                const [start, end] = dateRange || [];
                if (!start || !end) {
                    return vacations.reduce((total, v) => {
                        const vStart = moment(v.startDate).startOf('day');
                        const vEnd = moment(v.endDate).endOf('day');
                        return total + (vEnd.diff(vStart, 'days') + 1);
                    }, 0);
                }
    
                const rangeStart = moment(start).startOf('day');
                const rangeEnd = moment(end).endOf('day');
    
                const relevantVacations = vacations.filter(vacation => {
                    const vacationStart = moment(vacation.startDate).startOf('day');
                    const vacationEnd = moment(vacation.endDate).endOf('day');
    
                    if (filterType === 'start') {
                        return vacationStart.isBetween(rangeStart, rangeEnd, 'day', '[]');
                    } else {
                        return vacationEnd.isBetween(rangeStart, rangeEnd, 'day', '[]');
                    }
                });
    
                return relevantVacations.reduce((total, v) => {
                    const vStart = moment(v.startDate).startOf('day');
                    const vEnd = moment(v.endDate).endOf('day');
                    return total + (vEnd.diff(vStart, 'days') + 1);
                }, 0);
            }
        }
    ];
    
    const filteredEmployees = filterEmployees();

    const exportToCSV = () => {
        const displayedData = filteredEmployees.map((emp, index) => {
            const relevantVacation = emp.vacations && emp.vacations.length > 0
                ? emp.vacations.find(v => {
                    const vacationStart = moment(v.startDate);
                    const vacationEnd = moment(v.endDate);
                    const [start, end] = dateRange || [];
                    
                    if (!start || !end) return true;
                    
                    if (filterType === 'start') {
                        return moment(vacationStart).isBetween(start, end, 'day', '[]');
                    }
                    return moment(vacationEnd).isBetween(start, end, 'day', '[]');
                })
                : null;

            return {
                'SN': index + 1,
                'E-CODE': emp.eCode,
                'EMPLOYEE NAME': emp.employeeName,
                'START DATE': relevantVacation 
                    ? moment(relevantVacation.startDate).format('YYYY-MM-DD')
                    : 'N/A',
                'END DATE': relevantVacation 
                    ? moment(relevantVacation.endDate).format('YYYY-MM-DD')
                    : 'N/A',
                'DAYS': relevantVacation
                    ? moment(relevantVacation.endDate).diff(moment(relevantVacation.startDate), 'days') + 1
                    : 0
            };
        });

        const csv = Papa.unparse(displayedData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `vacation_report_${moment().format('YYYY-MM-DD')}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <>
            <Navbar />
            <div className=''>
                <div className='dashboard'>
                    <div style={{ padding: '2vh 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Select
                            style={{ width: '35%', fontFamily: 'var(--font)' }}
                            placeholder="Select company"
                            value={companyId}
                            onChange={(value) => {
                                handleCompanyChange(value);
                            }}
                        >
                            <Option value={null}>All Companies</Option>
                            {companies.map((company) => (
                                <Option key={company._id} value={company._id}>
                                    {company.companyName}
                                </Option>
                            ))}
                        </Select>
                
                        <RangePicker
    style={{ width: '35%' }}
    onChange={(dates, dateStrings) => {
        if (dates) {
            setDateRange([
                moment(dateStrings[0]),
                moment(dateStrings[1])
            ]);
        } else {
            setDateRange([null, null]);
        }
    }}
    format="YYYY-MM-DD"
/>

                        <Select
                            style={{ width: '20%' }}
                            defaultValue="start"
                            onChange={(value) => setFilterType(value)}
                        >
                            <Option value="start">Filter by Start Date</Option>
                            <Option value="end">Filter by End Date</Option>
                        </Select>

                        <Button
                            style={{ backgroundColor: "#6FB555", margin: "1vh 1vw", padding: '0 1vw', height: '23px' }}
                            type="primary"
                            onClick={exportToCSV}
                        >
                            Export to CSV
                        </Button>
                    </div>

                    <div style={{ width: '100%', marginTop: '1rem' }}>
                    <Table
    pagination={{ pageSize: 100, current: currentPage, onChange: (page) => setCurrentPage(page) }}
    dataSource={filteredEmployees}  // Ensure this contains the correct data
    loading={!dataLoaded}
    columns={columns}
    scroll={{
        x: 'max-content',
        y: `calc(70vh - 250px)`
    }}
    size='middle'
 />

                    </div>

                </div>
            </div>
        </>
    );
};

export default ManVacationReports;
