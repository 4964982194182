import {
	Button,
	Popconfirm,
	Select,
	Table,
	message,
	DatePicker,
	Modal,
	Form,
	Upload,
	Space
  } from 'antd';
  import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
  import moment from 'moment';
  import Papa from 'papaparse';
  import React, { useEffect, useState } from 'react';
  import './VacationReports.css';
  import AdminNavbar from './components/AdminNavbar';
  
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  
  const VacationReports = () => {
	const [vacationData, setVacationData] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [companyId, setCompanyId] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const pageSize = 100;
  
	const [dateRange, setDateRange] = useState([null, null]);
	const [filterType, setFilterType] = useState('start');
  
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(null);
  
	// We'll store the uploaded file in state ourselves
	const [fileList, setFileList] = useState([]);
  
	// Ant Design Form instance
	const [form] = Form.useForm();
  
	// 1) Fetch admin's company ID
	const fetchCompany = async () => {
	  try {
		const token = localStorage.getItem('token');
		const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
		  headers: {
			Authorization: `Bearer ${token}`,
		  },
		});
		const data = await response.json();
		if (response.ok) {
		  setCompanyId(data.company._id);
		} else {
		  console.error('Failed to fetch admin company:', data.error);
		}
	  } catch (error) {
		console.error('Error:', error);
	  }
	};
  
	// 2) Fetch all employees for that company
	const fetchEmployees = async () => {
	  if (!companyId) return;
	  const token = localStorage.getItem('token');
  
	  try {
		const response = await fetch(
		  `https://hrms-5u7j.onrender.com/admin/getEmployeesByCompany/${companyId}`,
		  {
			headers: {
			  Authorization: `Bearer ${token}`,
			},
		  }
		);
		const data = await response.json();
  
		if (!response.ok) {
		  console.error('Error fetching vacation data:', data.error);
		  message.error('Failed to fetch vacation data');
		  return;
		}
  
		// Flatten each employee's vacations for the table
		const flattenedData = data.flatMap((employee) =>
		  employee.vacations.map((vacation) => ({
			status: employee.status,
			employeeName: employee.employeeName,
			eCode: employee.eCode,
			...vacation,
		  }))
		);
  
		setVacationData(flattenedData);
		setDataLoaded(true);
	  } catch (error) {
		console.error('Error:', error);
		message.error('Failed to fetch vacation data');
	  }
	};
  
	useEffect(() => {
	  fetchCompany();
	}, []);
  
	useEffect(() => {
	  // Refetch employees whenever companyId, dateRange, or filterType changes
	  fetchEmployees();
	}, [companyId, dateRange, filterType]);
  
	// 3) Filter logic
	const filterEmployees = () => {
	  const [start, end] = dateRange || [];
	  if (!start || !end) return vacationData;
  
	  return vacationData.filter((record) => {
		const vacationStart = moment(record.startDate);
		const vacationEnd = moment(record.endDate);
  
		if (filterType === 'start') {
		  // Filter by start date
		  return vacationStart.isBetween(start, end, 'day', '[]');
		} else {
		  // Filter by end date
		  return vacationEnd.isBetween(start, end, 'day', '[]');
		}
	  });
	};
  
	const filteredEmployees = filterEmployees();
  
	// 4) Table columns
	const columns = [
		{
		  title: 'SN',
		  dataIndex: 'serial',
		  key: 'serial',
		  fixed: 'left',
		  render: (_, __, index) => index + 1,
		},
		{
		  title: 'E-CODE',
		  dataIndex: 'eCode',
		  key: 'eCode',
		  fixed: 'left',
		},
		{
		  title: 'EMPLOYEE NAME',
		  dataIndex: 'employeeName',
		  key: 'employeeName',
		},
		{
		  title: 'STATUS',
		  dataIndex: 'status',
		  key: 'status',
		  // <-- Add a render function to color-code the status text
		  render: (status) => {
			let color;
			switch (status) {
			  case 'Active':
				color = '#10ca10'; // green
				break;
			  case 'Terminated/Resigned':
				color = 'red';     // red
				break;
			  default:
				color = 'orange';  // fallback
			}
	  
			return (
			  <span style={{ color }}>
				{status}
			  </span>
			);
		  },
		},
		{
		  title: 'START DATE',
		  dataIndex: 'startDate',
		  key: 'startDate',
		  render: (startDate) => (startDate ? moment(startDate).format('YYYY-MM-DD') : 'N/A'),
		},
		{
		  title: 'END DATE',
		  dataIndex: 'endDate',
		  key: 'endDate',
		  render: (endDate) => (endDate ? moment(endDate).format('YYYY-MM-DD') : 'N/A'),
		},
		{
		  title: 'DAYS',
		  dataIndex: 'days',
		  key: 'days',
		  render: (_, record) => {
			if (!record.startDate || !record.endDate) return 0;
			return moment(record.endDate).diff(moment(record.startDate), 'days') + 1;
		  },
		},
		{
		  title: 'Action',
		  key: 'action',
		  width: 200,
		  render: (_, employee) => (
			<Space>
			  <Popconfirm
				title="Delete the Employee"
				description="Are you sure to delete this vacation?"
				onConfirm={() => handleDeleteVacation(employee)}
				okText="Yes"
				cancelText="No"
			  >
				<Button danger icon={<DeleteOutlined />} />
			  </Popconfirm>
			  <Button
				type="primary"
				onClick={() => showSettleLeaveModal(employee)}
			  >
				Settle Leave
			  </Button>
			</Space>
		  ),
		},
	  ];
	  
	// 5) Delete vacation
	const handleDeleteVacation = async (employee) => {
	  const token = localStorage.getItem('token');
	  try {
		const response = await fetch(
		  `https://hrms-5u7j.onrender.com/admin/deleteVacation`,
		  {
			method: 'DELETE',
			headers: {
			  'Content-Type': 'application/json',
			  Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({ eCode: employee.eCode, vacationId: employee._id }),
		  }
		);
		const data = await response.json();
		if (response.ok) {
		  message.success('Vacation deleted successfully');
		  fetchEmployees();
		} else {
		  console.error('Failed to delete vacation:', data);
		  message.error('Failed to delete vacation');
		}
	  } catch (error) {
		console.error('Error:', error);
	  }
	};
  
	// 6) Show "Settle Leave" Modal
	const showSettleLeaveModal = (employee) => {
	  setSelectedEmployee(employee);
	  setIsModalVisible(true);
	};
  
	// 7) Handle "Settle Leave" submission
	const handleSettleLeave = async (values) => {
	  if (!selectedEmployee) {
		message.error('No employee selected');
		return;
	  }
  
	  const token = localStorage.getItem('token');
	  const formData = new FormData();
  
	  // eCode
	  formData.append('eCode', selectedEmployee.eCode);
  
	  // Convert to ISO string
	  formData.append('startDate', moment(selectedEmployee.startDate).toISOString());
	  formData.append('endDate', moment(selectedEmployee.endDate).toISOString());
	  formData.append('settleDate', moment(values.settleDate).toISOString());
  
	  // Admin email
	  const adminEmail = localStorage.getItem('adminEmail') || 'unknown@domain.com';
	  formData.append('adminEmail', adminEmail);
  
	  // Add the file (if any)
	  if (fileList.length > 0) {
		const fileObj = fileList[0].originFileObj || fileList[0];
		formData.append('file', fileObj);
	  } else {
		// If the form requires a file, make sure to handle
		console.warn('No file selected, but required?');
	  }
  
	  // Debug logs
	  console.log('Selected Employee in handleSettleLeave:', selectedEmployee);
	  console.log('Form Values (only for date here):', values);
	  console.log('FormData contents:');
	  for (let [key, val] of formData.entries()) {
		if (val instanceof File) {
		  console.log(`${key}: [File] ${val.name}`);
		} else {
		  console.log(`${key}: ${val}`);
		}
	  }
  
	  try {
		const response = await fetch('https://hrms-5u7j.onrender.com/admin/settle-leave', {
		  method: 'POST',
		  headers: {
			Authorization: `Bearer ${token}`,
		  },
		  body: formData,
		});
  
		const responseText = await response.text();
		console.log('Raw Server Response:', responseText);
  
		let data;
		try {
		  data = JSON.parse(responseText);
		} catch (err) {
		  console.error('Failed to parse response as JSON:', err);
		  message.error('Unexpected server response format.');
		  return;
		}
  
		if (response.ok) {
		  message.success('Leave settled successfully');
		  fetchEmployees();
		  setIsModalVisible(false);
		  setFileList([]);
		  form.resetFields();
		} else {
		  console.error('Server Error Response:', data);
		  message.error(data.message || 'Failed to settle leave');
		}
	  } catch (error) {
		console.error('Request Error:', error);
		message.error(`Failed to settle leave: ${error.message}`);
	  }
	};
  
	// 8) Export to CSV
	const exportToCSV = () => {
	  const displayedData = filteredEmployees.map((emp, index) => ({
		SN: index + 1,
		'E-CODE': emp.eCode,
		'EMPLOYEE NAME': emp.employeeName,
		'START DATE': emp.startDate ? moment(emp.startDate).format('YYYY-MM-DD') : 'N/A',
		'END DATE': emp.endDate ? moment(emp.endDate).format('YYYY-MM-DD') : 'N/A',
		DAYS: emp.endDate
		  ? moment(emp.endDate).diff(moment(emp.startDate), 'days') + 1
		  : 0,
	  }));
  
	  const csv = Papa.unparse(displayedData);
	  const blob = new Blob([csv], { type: 'text/csv' });
	  const url = window.URL.createObjectURL(blob);
	  const a = document.createElement('a');
	  a.style.display = 'none';
	  a.href = url;
	  a.download = `vacation_report_${moment().format('YYYY-MM-DD')}.csv`;
	  document.body.appendChild(a);
	  a.click();
	  window.URL.revokeObjectURL(url);
	};
  
	// Fix for ResizeObserver errors
	useEffect(() => {
	  window.addEventListener('error', (e) => {
		if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
		  const resizeObserverErrDiv = document.getElementById(
			'webpack-dev-server-client-overlay-div'
		  );
		  const resizeObserverErr = document.getElementById(
			'webpack-dev-server-client-overlay'
		  );
		  if (resizeObserverErr) {
			resizeObserverErr.setAttribute('style', 'display: none');
		  }
		  if (resizeObserverErrDiv) {
			resizeObserverErrDiv.setAttribute('style', 'display: none');
		  }
		}
	  });
	}, []);
  
	return (
	  <>
		<AdminNavbar />
  
		<div className="dashboard mt-3">
		  {/* Filters */}
		  <div
			style={{
			  padding: '1vh 0',
			  display: 'flex',
			  justifyContent: 'space-between',
			  alignItems: 'center',
			}}
		  >
			<RangePicker
			  style={{ width: '35%' }}
			  onChange={(dates, dateStrings) => {
				if (dates) {
				  setDateRange([moment(dateStrings[0]), moment(dateStrings[1])]);
				} else {
				  setDateRange([null, null]);
				}
			  }}
			  format="YYYY-MM-DD"
			/>
  
			<Select
			  style={{ width: '20%' }}
			  defaultValue="start"
			  onChange={(value) => setFilterType(value)}
			>
			  <Option value="start">Filter by Start Date</Option>
			  <Option value="end">Filter by End Date</Option>
			</Select>
  
			<Button
			  style={{
				backgroundColor: '#6FB555',
				margin: '1vh 1vw',
				padding: '0 1vw',
				height: '23px',
			  }}
			  type="primary"
			  onClick={exportToCSV}
			>
			  Export to CSV
			</Button>
		  </div>
  
		  {/* Table */}
		  <div style={{ width: '100%', marginTop: '1rem' }}>
			<Table
			  pagination={{
				pageSize,
				current: currentPage,
				onChange: (page) => setCurrentPage(page),
			  }}
			  dataSource={filteredEmployees}
			  loading={!dataLoaded}
			  columns={columns}
			  scroll={{
				x: 'max-content',
				y: `calc(70vh - 250px)`,
			  }}
			  size="middle"
			  rowKey={(record) => record._id || Math.random()}
			/>
		  </div>
		</div>
  
		{/* Modal for Settle Leave */}
	<Modal
  title="Settle Leave"
  visible={isModalVisible}
  onCancel={() => {
    setIsModalVisible(false);
    form.resetFields();
    setFileList([]);
  }}
  footer={null}
>
  <Form
    form={form}
    onFinish={handleSettleLeave}
    layout="horizontal"
    labelCol={{ span: 6 }}
    wrapperCol={{ span: 18 }}
  >
    <Form.Item
      label="Settle Date"
      name="settleDate"
      rules={[{ required: true, message: 'Please select the settle date!' }]}
    >
      <DatePicker format="YYYY-MM-DD" />
    </Form.Item>

    <Form.Item label="Upload File" required>
      <Upload
        fileList={fileList}
        beforeUpload={(file) => {
          setFileList([file]);
          return false; // Prevent auto-upload
        }}
        onRemove={() => setFileList([])}
      >
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
    </Form.Item>

    {/* Offset the button so it aligns under the input fields */}
    <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
</Modal>

	  </>
	);
  };
  
  export default VacationReports;
  