import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import ManagerSignUp from './ManagerSignUp';
import ManagerAdmins from './ManagerAdmins';
import Login from './ManagerLogin';
import AdminLogin from './AdminLogin';
import EmployeeDataDashboard from './EmployeeDataDashboard';
import EmployeeDetails from './EmployeeDetails';
import AddEmployee from './addEmployee';
import EmployeeTimeline from './EmployeeTimeline'; // Import your new component
import EmployeePage from './EmployeePage';
import Admin from './ClientAdminRouter';
import AnalysisReporting from './AnalysisReporting';
import Earnings from './Earnings';
import HumanResourceForms from './HumanResourceForms';
import Settings from './Settings';
import ManagerCompanies from './ManagerCompanies';
import { DepartmentsProvider } from './DepartmentsContext';
import SalariesAndPayroll from './Salaries&Payroll';
import PayrollSetup from './PayrollSetup';
import SavedFiles from './SavedFiles';
import WarningLetter from './HRforms/WarningLetter';
import LeaveApplication from './HRforms/LeaveApplication';
import RejoiningForm from './HRforms/RejoinForm';
import EmployeeSickLeave from './HRforms/SickLeave';
import ManagerRouter from './ClientManagerRouter';
import ManEmployeeDataDashboard from './ManEmployeeDataDashboard';
import ManAnalysisReporting from './ManAnalysisReporting';
import ContractResidential from './HRforms/ContractResidential'
import EmployeeEvaluation from './HRforms/EmployeeEvaluation'
import LoanRequest from './HRforms/LoanRequest'
import EmployeeData from './HRforms/EmployeeData'
import EmploymentTermination from './HRforms/EmploymentTermination'
import ExitInterview from './HRforms/ExitInterview'
import TrainingAttendance from './HRforms/TrainingAttendance'
import TrainingRequirement from './HRforms/TrainingRequirement'
import EmployeeProbationaryEvaluation from './HRforms/EmployeeProbationaryEvaluation'
import TrainingNeedAssessment from './HRforms/TrainingNeedAssessment'
import AnnualTraining from './HRforms/AnnualTraining'
import TravelAgency from './HRforms/TravelAgency'
import ManEmployeePage from './ManEmployeePage';
import ManAnalyisReportingRouter from './ManAnalysisReportingRouter';
import ManDateReports from './ManDateReports';
import './App.css';
import AdminAnalyisReportingRouter from './AdminAnalysisReportingRouter';
import DateReports from './DateReports';
import VacationReports from './VacationReports';
import ManVacationReports from './ManVacationReports';
import ManHumanResourceForms from './ManHumanResourceForms';
import ManSettings from './ManSettings';

import ManContractResidential from './ManHRforms/ContractResidential'
import ManEmployeeEvaluation from './ManHRforms/EmployeeEvaluation'
import ManLoanRequest from './ManHRforms/LoanRequest'
import ManEmployeeData from './ManHRforms/EmployeeData'
import ManEmploymentTermination from './ManHRforms/EmploymentTermination'
import ManExitInterview from './ManHRforms/ExitInterview'
import ManTrainingAttendance from './ManHRforms/TrainingAttendance'
import ManTrainingRequirement from './ManHRforms/TrainingRequirement'
import ManEmployeeProbationaryEvaluation from './ManHRforms/EmployeeProbationaryEvaluation'
import ManTrainingNeedAssessment from './ManHRforms/TrainingNeedAssessment'
import ManAnnualTraining from './ManHRforms/AnnualTraining'
import ManTravelAgency from './ManHRforms/TravelAgency'
import ManWarningLetter from './ManHRforms/WarningLetter';
import ManLeaveApplication from './ManHRforms/LeaveApplication';
import ManRejoiningForm from './ManHRforms/RejoinForm';
import ManEmployeeSickLeave from './ManHRforms/SickLeave';
import AdminNavbar from './components/AdminNavbar';
import LookerStudio from './LookerStudio';
import Navbar from './components/Navbar';
import { ConfigProvider, theme } from 'antd';
import ManApprovals from './ManApprovals';
import Approvals from './Approvals';
import LeaveApproval from './Approvals/LeaveApproval';
import ManLeaveApproval from './Approvals/ManLeaveApproval';
import ManTerminationApproval from './Approvals/ManTerminationApproval';
import TerminationApproval from './Approvals/TerminationApproval';
import SickLeaveReports from './SickLeaveReports';
import ManSickLeaveReports from './ManSickLeaveReports';
import ManagerPage from './ManagerPage';
import AccommodationManagement from './ManAccommodationManagement';
import VehiclesManagement from './ManVehicleManagement';
import VehichleDetails from './VehicleDetails';
import ResetPassword from './resetPassword';
function App() {


  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <div className="App">

        <Router>
          <DepartmentsProvider>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/reset-admin-password" element={<ResetPassword/>} />
              <Route path="/managerSignUp" element={<ManagerSignUp />} />

              <Route path='/manager/looker-studio' element={<Navbar />} />
              <Route path='/admin/looker-studio' element={<AdminNavbar />} />

              <Route path='/manager/leave-approval/:id' element={<ManLeaveApproval />} />
              <Route path='/manager/termination-approval/:id' element={<ManTerminationApproval />} />

              <Route path="/manager/approvals" element={<ManApprovals />} />
              {/* <Route path="/manager/temp" element={<ManagerRouter />} /> */}
              <Route path="/managerAdmins/:companyName" element={<ManagerAdmins />} />
              <Route path="/manager/managerCompanies" element={<ManagerPage />} />
              <Route path="/manager" element={<ManagerRouter />} />
              <Route path="/manager/employeeDataDashboard" element={<ManEmployeeDataDashboard />} />
              <Route path="/manager/analysis" element={<ManAnalyisReportingRouter />} />
              <Route path="/manager/analysis/reporting" element={<ManAnalysisReporting />} />
              <Route path="/manager/analysis/date-reports" element={<ManDateReports />} />
              <Route path="/manager/analysis/sick-leave-reports" element={<ManSickLeaveReports />} />
              <Route path="/manager/employee-details/:id" element={<ManEmployeePage />} />
              <Route path="/manager/analysis/vacation-reports" element={<ManVacationReports />} />
              <Route path="/manager/hr-forms" element={<ManHumanResourceForms />} />
              <Route path="/manager/settings" element={<ManSettings />} />
              <Route path="/manager/hr-forms/warning-letter" element={<ManWarningLetter />} />
              <Route path="/manager/hr-forms/warning-letter/:ecode" element={<ManWarningLetter />} />
              <Route path="/manager/hr-forms/leave-application/:ecode" element={<ManLeaveApplication />} />
              <Route path="/manager/hr-forms/leave-application" element={<ManLeaveApplication />} />
              <Route path="/manager/hr-forms/rejoining-form" element={<ManRejoiningForm />} />
              <Route path="/manager/hr-forms/rejoining-form/:ecode" element={<ManRejoiningForm />} />

              <Route path="/manager/hr-forms/sick-leave" element={<ManEmployeeSickLeave />} />
              <Route path="/manager/hr-forms/sick-leave/:ecode" element={<ManEmployeeSickLeave />} />
              <Route path="/manager/hr-forms/contract-residential" element={<ManContractResidential />} />
              <Route path="/manager/hr-forms/employee-evaluation" element={<ManEmployeeEvaluation />} />
              <Route path="/manager/hr-forms/loan-request" element={<ManLoanRequest />} />
              <Route path="/manager/hr-forms/employee-data" element={<ManEmployeeData />} />
              <Route path="/manager/hr-forms/employment-termination" element={<ManEmploymentTermination />} />
              <Route path="/manager/hr-forms/employment-termination/:ecode" element={<ManEmploymentTermination />} />
              <Route path="/manager/hr-forms/exit-interview" element={<ManExitInterview />} />
              <Route path="/manager/hr-forms/training-attendance" element={<ManTrainingAttendance />} />
              <Route path="/manager/hr-forms/training-requirement" element={<ManTrainingRequirement />} />
              <Route path="/manager/hr-forms/employee-probationary-evaluation" element={<ManEmployeeProbationaryEvaluation />} />
              <Route path="/manager/hr-forms/training-need-assessment" element={<ManTrainingNeedAssessment />} />
              <Route path="/manager/hr-forms/annual-training" element={<ManAnnualTraining />} />
              <Route path="/manager/hr-forms/travel-agency" element={<ManTravelAgency />} />
              <Route path="/manager/accommodation-management" element={<AccommodationManagement />} />
              <Route path="/manager/vehicle-management" element={<VehiclesManagement />} />
              <Route path="/manager/vehicles/:id" element={<VehichleDetails />} />
             
              <Route path="/admin/analysis-reporting-router" element={<AdminAnalyisReportingRouter />} />
              <Route path="/admin/analysis/date-reports" element={<DateReports />} />
              <Route path="/admin/analysis/vacation-reports" element={<VacationReports />} />
              <Route path="/admin/analysis/sick-leave-reports" element={<SickLeaveReports />} />
              <Route path="/admin/approvals" element={<Approvals />} />
              <Route path='/admin/leave-approval/:id' element={<LeaveApproval />} />
              <Route path='/admin/termination-approval/:id' element={<TerminationApproval />} />

              <Route path="/adminLogin" element={<AdminLogin />} />
              <Route path="/admin/employeeDataDashboard" element={<EmployeeDataDashboard />} />
              <Route path="/admin/employee-details/:id" element={<EmployeePage />} />
              <Route path="/admin/addEmployee" element={<AddEmployee />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/admin/analysis/reporting" element={<AnalysisReporting />} />
              <Route path="/admin/hr-forms" element={<HumanResourceForms />} />
              <Route path="/admin/settings" element={<Settings />} />
              <Route path="/admin/earnings" element={<Earnings />} />
              <Route path="/admin/earnings/salaries" element={<SalariesAndPayroll />} />
              <Route path="/admin/earnings/payroll-setup" element={<PayrollSetup />} />
              <Route path="/admin/savedfiles" element={<SavedFiles />} />
              <Route path="/admin/hr-forms/warning-letter" element={<WarningLetter />} />
              <Route path="/admin/hr-forms/warning-letter/:ecode" element={<WarningLetter />} />
              <Route path="/admin/hr-forms/leave-application" element={<LeaveApplication />} />
              <Route path="/admin/hr-forms/leave-application/:ecode" element={<LeaveApplication />} />
              <Route path="/admin/hr-forms/rejoining-form" element={<RejoiningForm />} />
              <Route path="/admin/hr-forms/rejoining-form/:ecode" element={<RejoiningForm />} />
              <Route path="/admin/hr-forms/sick-leave" element={<EmployeeSickLeave />} />
              <Route path="/admin/hr-forms/sick-leave/:ecode" element={<EmployeeSickLeave />} />
              <Route path="/admin/hr-forms/loan-request" element={<LoanRequest />} />
              <Route path="/admin/hr-forms/loan-request/:ecode" element={<LoanRequest />} />
              <Route path="/admin/hr-forms/employee-data" element={<EmployeeData />} />
              <Route path="/admin/hr-forms/employee-data/:ecode" element={<EmployeeData />} />
              <Route path="/admin/hr-forms/employment-termination" element={<EmploymentTermination />} />
              <Route path="/admin/hr-forms/employment-termination/:ecode" element={<EmploymentTermination />} />
              <Route path="/admin/hr-forms/contract-residential" element={<ContractResidential />} />
              <Route path="/admin/hr-forms/employee-evaluation" element={<EmployeeEvaluation />} />
              <Route path="/admin/hr-forms/exit-interview" element={<ExitInterview />} />
              <Route path="/admin/hr-forms/training-attendance" element={<TrainingAttendance />} />
              <Route path="/admin/hr-forms/training-requirement" element={<TrainingRequirement />} />
              <Route path="/admin/hr-forms/employee-probationary-evaluation" element={<EmployeeProbationaryEvaluation />} />
              <Route path="/admin/hr-forms/training-need-assessment" element={<TrainingNeedAssessment />} />
              <Route path="/admin/hr-forms/annual-training" element={<AnnualTraining />} />
              <Route path="/admin/hr-forms/travel-agency" element={<TravelAgency />} />
            </Routes>

          </DepartmentsProvider>
          <LookerStudio />
        </Router>
      </div>
    </ConfigProvider>

  );
}

export default App;
