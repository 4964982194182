import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Table, Button, message, Modal, Form, Input, Checkbox, Select, Popconfirm } from 'antd';
import './ManagerAdmins.css';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Navbar from './components/Navbar';
const { Option } = Select;

const ManagerAdmins = () => {
  const [admins, setAdmins] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDepartmentModalVisible, setIsDepartmentModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [departmentForm] = Form.useForm();
const [showPasswords, setShowPasswords] = useState(false);
const [showDepartments, setShowDepartments] = useState(false);
const { companyName } = useParams();
const [departments, setDepartments] = useState([]);
const [isEditEmailModalVisible, setIsEditEmailModalVisible] = useState(false);
const [selectedAdmin, setSelectedAdmin] = useState(null);


  useEffect(() => {
    fetchAdmins();
  }, []);
  useEffect(() => {
	if (companyName) {
	  fetchDepartments(companyName);
	}
  }, [companyName]);

  const fetchAdmins = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://hrms-5u7j.onrender.com/manager/company/${encodeURIComponent(companyName)}`, {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setAdmins(data.admins);
      } else {
        message.error(data.error || 'Failed to fetch admins');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to fetch admins');
    }
  };

  const fetchDepartments = async (companyName) => {
	try {
	  const token = localStorage.getItem('token');
	  const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getDepartmentsE/${companyName}`, {
		method: 'GET',
		headers: {
		  'Authorization': 'Bearer ' + token,
		},
	  });
	  const data = await response.json();
	  if (response.ok) {
		setDepartments(data);
	  } else {
		console.error('Failed to fetch departments:', data.error);
	  }
	} catch (error) {
	  console.error('Error:', error);
	}
  };
  // Reset password function
  const resetPassword = async (adminEmail) => {
	try {
	  const response = await fetch(`https://hrms-5u7j.onrender.com/manager/resetPassword`, {
		method: 'POST', // POST request
		headers: {
		  'Authorization': 'Bearer ' + localStorage.getItem('token'),
		  'Content-Type': 'application/json',
		},
		body: JSON.stringify({ adminEmail: adminEmail }), // Send email in the body
	  });
  
	  const data = await response.json();
  
	  if (response.ok) {
		message.success('Password reset link sent to email');
	  } else {
		message.error(data.error || 'Failed to send reset email');
	  }
	} catch (error) {
	  console.error('Error:', error);
	  message.error('Failed to send reset email');
	}
  };
  

  const deleteAdmin = async (adminEmail) => {
    try {
      const response = await fetch(`https://hrms-5u7j.onrender.com/manager/${encodeURIComponent(adminEmail)}`, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        message.success('Admin deleted successfully');
        setAdmins(admins.filter((admin) => admin.adminEmail !== adminEmail));
      } else {
        const data = await response.json();
        message.error(data.error || 'Failed to delete admin');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to delete admin');
    }
  };

  const createAdmin = async () => {
    const adminEmail = form.getFieldValue('adminEmail');
    const adminPassword = form.getFieldValue('adminPassword');
    const isAdminWithFullAccess = form.getFieldValue('isAdminWithFullAccess');

    try {
      const response = await fetch(`https://hrms-5u7j.onrender.com/manager/createAdmin/${encodeURIComponent(companyName)}`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          adminEmail,
          adminPassword,
          isAdminWithFullAccess,
          companyName: companyName,
        }),
      });

      if (response.ok) {
        message.success('Admin created successfully');
        setIsModalVisible(false);
        form.resetFields();
        fetchAdmins();
      } else {
        const data = await response.json();
        message.error(data.error || 'Failed to create admin');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to create admin');
    }
  };

 
  const createDepartment = async () => {
	const departmentName = departmentForm.getFieldValue('departmentName');
  
	try {
	  const response = await fetch(`https://hrms-5u7j.onrender.com/manager/createDepartment`, {
		method: 'POST',
		headers: {
		  'Authorization': 'Bearer ' + localStorage.getItem('token'),
		  'Content-Type': 'application/json',
		},
		body: JSON.stringify({
		  companyName: companyName,
		  departmentName: departmentName,
		}),
	  });
  
	  if (response.ok) {
		message.success('Department created successfully');
		setIsDepartmentModalVisible(false);
		departmentForm.resetFields();
		fetchAdmins();
	  } else {
		const data = await response.json();
		message.error(data.error || 'Failed to create department');
	  }
	} catch (error) {
	  console.error('Error:', error);
	  message.error('Failed to create department');
	}
  };
  const openEditEmailModal = (admin) => {
	setSelectedAdmin(admin);  // Store the admin info to be updated
	form.setFieldsValue({
	  adminEmail: admin.adminEmail,  // Pre-fill the form with the current admin email
	});
	setIsEditEmailModalVisible(true);  // Open the modal
  };
  
  
  const handleUpdateEmail = async () => {
	const updatedEmail = form.getFieldValue('adminEmail');
	const oldEmail = selectedAdmin.adminEmail;  // Get the current admin email
  
	try {
	  const response = await fetch(`https://hrms-5u7j.onrender.com/manager/updateAdminEmail`, {
		method: 'POST',
		headers: {
		  'Authorization': 'Bearer ' + localStorage.getItem('token'),
		  'Content-Type': 'application/json',
		},
		body: JSON.stringify({
		  oldAdminEmail: oldEmail,  // Send the old email
		  newAdminEmail: updatedEmail,  // Send the new email
		}),
	  });
  
	  const data = await response.json();
  
	  if (response.ok) {
		message.success('Admin email updated successfully');
		fetchAdmins();  // Refresh the admin list
		setIsEditEmailModalVisible(false);
	  } else {
		message.error(data.error || 'Failed to update admin email');
	  }
	} catch (error) {
	  console.error('Error:', error);
	  message.error('Failed to update admin email');
	}
  };
  

  const adminColumns = [
	{
	  title: 'Admin Email',
	  dataIndex: 'adminEmail',
	  key: 'adminEmail',
	  width: '30vw',
	},
	{
	  title: 'Action',
	  key: 'action',
	  render: (text, record) => (
		<div>
		  {/* Reset Password Button */}
		  <Button onClick={() => resetPassword(record.adminEmail)} style={{ marginRight: '8px' }}>
			Reset Password
		  </Button>
  
		 {/* Edit Button */}
<Button 
  onClick={() => openEditEmailModal(record)} 
  style={{ marginRight: '8px' }} 
  disabled={false}  // Disables the button
>
  Edit Email
</Button>
  
		  {/* Delete Button */}
		  <Popconfirm
			title="Delete Admin"
			description="Are you sure to delete this admin?"
			onConfirm={() => deleteAdmin(record.adminEmail)}
			okText="Yes"
			cancelText="No"
		  >
			<Button danger>Delete</Button>
		  </Popconfirm>
		</div>
	  ),
	},
  ];
  

  return (
    <>
      <Navbar />
      <div className=''>
        <div className='dashboard' style={{ justifyContent: 'flex-start' }}>
          <div>
            <div className='d-flex gap-3'>
              <Button className='create-button m-0 w-25' type="primary" onClick={() => setIsModalVisible(true)}>
                Create Admin
              </Button>
            </div>
            <br />
			{showDepartments && (
						<div className="departments-list mb-4">
							<h3>Departments:</h3>
							<table className="departments-table">
								<thead>

								</thead>
								<tbody>
									{departments.map((department) => (
										<tr key={department._id}>
											<td>{department.departmentName}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
            <Table style={{ width: '100%' }} dataSource={admins} columns={adminColumns} rowKey="adminEmail" />

            <Modal
              title="Create Admin"
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              footer={null}
            >
              <Form form={form} onFinish={createAdmin}>
                <Form.Item
                  name="adminEmail"
                  rules={[{ required: true, message: 'Please enter the admin email' }]}
                >
                  <Input placeholder="Admin Email" />
                </Form.Item>
                <Form.Item
                  name="adminPassword"
                  rules={[{ required: true, message: 'Please enter the admin password' }]}
                >
                  <Input.Password placeholder="Admin Password" />
                </Form.Item>
                <Form.Item name="isAdminWithFullAccess" valuePropName="checked">
                  <Checkbox>Admin with Full Access</Checkbox>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
              </Form>
            </Modal>
			<Modal
						title="Create Department"
						visible={isDepartmentModalVisible}
						onCancel={() => setIsDepartmentModalVisible(false)}
						footer={null}
					>
						<Form form={departmentForm} onFinish={createDepartment}>
							<Form.Item name="departmentName">
								<Select placeholder="Select a Department">
									<Option value="hr">Human Resource</Option>
									<Option value="finance">Finance</Option>
									<Option value="operations">Operations</Option>
									<Option value="admin">Admin</Option>
									<Option value="legal">Legal</Option>
								</Select>
							</Form.Item>
							<Button type="primary" htmlType="submit">
								Create
							</Button>
						</Form>
					</Modal>
					<div>
					<Modal
  title="Edit Admin Email"
  visible={isEditEmailModalVisible}  // Ensure this is true to show the modal
  onCancel={() => setIsEditEmailModalVisible(false)}
  footer={null}
>
  <Form form={form} onFinish={handleUpdateEmail}>
    <Form.Item
      name="adminEmail"
      label="Admin Email"
      rules={[{ required: true, message: 'Please enter the admin email' }]}
    >
      <Input />
    </Form.Item>
    <Button type="primary" htmlType="submit">
      Update Email
    </Button>
  </Form>
</Modal>

</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerAdmins;
