import React, { useState, useEffect, useCallback } from 'react';
import { Table, Select, message, Button,DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import { HomeFilled } from '@ant-design/icons';
import moment from 'moment';
import Papa from 'papaparse';
import './ManDateReports.css'; // Please create a new CSS file for this module.
import AdminNavbar from './components/AdminNavbar';
const ManDateReports = () => {
    const [employees, setEmployees] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [companies, setCompanies] = useState([]);
    const { Option } = Select;
    const [filterType, setFilterType] = useState('withinAMonth'); // default to "expiry within a month"
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);  // Maintain the current page in the state
    const pageSize = 100;  // As per your current setting
    const [selectedExpiryColumns, setSelectedExpiryColumns] = useState([]); // State for selected expiry columns
    const [dateRange, setDateRange] = useState([null, null]);
    const { RangePicker } = DatePicker;

    const fetchCompany = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setCompanyId(data.company._id);  // Set the companyId in the state
            } else {
                console.error('Failed to fetch admin company:', data.error);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchEmployees = useCallback(async () => {
        // Ensure companyId is set before making the fetch request
        if (!companyId) return;

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getEmployeesByCompany/${companyId}`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setEmployees(data);
                setDataLoaded(true);
            } else {
                message.error(data.message || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch employees');
        }
    }, [companyId]);

    useEffect(() => {
        fetchCompany();   // Fetch the company first
    }, []);

    useEffect(() => {
        if (companyId) {
            fetchEmployees(); // Only fetch employees if companyId is not null
        }
    }, [companyId, fetchEmployees]);

    const isExpiringWithinNextMonth = (dateString) => {
        const currentDate = moment();
        const expiryDate = moment(dateString);
        const differenceInDays = expiryDate.diff(currentDate, 'days');
        return differenceInDays >= 0 && differenceInDays <= 30;
    };

    const isExpired = (dateString) => {
        const currentDate = moment();
        const expiryDate = moment(dateString);
        return expiryDate.isBefore(currentDate);
    };

    const filterEmployees = () => {
        const [start, end] = dateRange || [];
        if (!start || !end) return []; // Ensure no data is shown if the date range is invalid
    
        return employees.filter(employee =>
            selectedExpiryColumns.some(col => {
                const date = employee[col];
                if (date && moment(date, moment.ISO_8601, true).isValid()) {
                    const expiryDate = moment(date);
                    return expiryDate.isBetween(moment(start), moment(end), 'day', '[]');
                }
                return false; // Exclude employees with no valid date
            })
        );
    };
    
    
    
    const checkDateValidity = (date) => {
        if (!date || !moment(date, moment.ISO_8601, true).isValid()) {
            return false; // Invalid or missing date
        }
    
        const expiryDate = moment(date);
        const [start, end] = dateRange || [];
        if (start && end) {
            return expiryDate.isBetween(moment(start), moment(end), 'day', '[]'); // Check if within range
        }
    
        return false; // Default to false if no range is selected
    };
    
    const expiryColumns = [

        {
            title: 'QID Expiry',
            dataIndex: 'qidExpiryDate',
            key: 'qidExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Health Card Expiry',
            dataIndex: 'healthCardExpiryDate',
            key: 'healthCardExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Health Certificate Expiry',
            dataIndex: 'healthCertificateExpiryDate',
            key: 'healthCertificateExpiryDate',
            // fixed: 'left',
            width: 250,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Driver License Expiry',
            dataIndex: 'driverLicenseExpiryDate',
            key: 'driverLicenseExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Passport Expiry',
            dataIndex: 'passportExpiryDate',
            key: 'passportExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Visa Expiry',
            dataIndex: 'visaExpiryDate',
            key: 'visaExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        }
    ];
    const generateColumns = () => {
        const validColumns = expiryColumns.filter(column =>
            selectedExpiryColumns.length === 0 || selectedExpiryColumns.includes(column.dataIndex)
        );
    
        return [
            {
                title: 'SN',
                dataIndex: 'serial',
                key: 'serial',
                render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
                sorter: (a, b) => a.serial - b.serial, // Sorting by serial number
            },
            {
                title: 'E-CODE',
                dataIndex: 'eCode',
                key: 'eCode',
                sorter: (a, b) => a.eCode - b.eCode, // Numeric sorting
            },
            {
                title: 'EMPLOYEE NAME',
                dataIndex: 'employeeName',
                key: 'employeeName',
                width: 200,
                sorter: (a, b) => a.employeeName.localeCompare(b.employeeName), // Alphabetical sorting
                render: text => <div style={{ wordWrap: 'break-word', maxWidth: '200px' }}>{text}</div>,
            },
            ...validColumns.map(column => ({
                ...column,
                sorter: (a, b) => {
                    const dateA = a[column.dataIndex] ? new Date(a[column.dataIndex]) : new Date(0);
                    const dateB = b[column.dataIndex] ? new Date(b[column.dataIndex]) : new Date(0);
                    return dateA - dateB;
                },
            })),
        ];
    };
    
    


    const filteredEmployees = filterEmployees();
    const columns = generateColumns();
    const mandatoryColumns = ['eCode', 'employeeName']; // Include any other columns you consider mandatory
    const exportToCSV = () => {
        const [startDate, endDate] = dateRange || [null, null];
    
        if (!startDate || !endDate) {
            message.error("Please select a valid date range.");
            return;
        }
    
        const displayedData = filteredEmployees
            .filter(emp =>
                selectedExpiryColumns.some(col => {
                    const date = emp[col];
                    if (date && moment(date, moment.ISO_8601, true).isValid()) {
                        const expiryDate = moment(date);
                        return expiryDate.isBetween(moment(startDate), moment(endDate), 'day', '[]');
                    }
                    return false;
                })
            )
            .map(emp => {
                const obj = {
                    eCode: emp.eCode || '',
                    employeeName: emp.employeeName || '',
                };
    
                selectedExpiryColumns.forEach(col => {
                    obj[col] = emp[col] ? moment(emp[col]).format('YYYY-MM-DD') : '';
                });
    
                return obj;
            });
    
        if (displayedData.length === 0) {
            message.warning("No employees found with dates in the selected range.");
            return;
        }
    
        const csv = Papa.unparse(displayedData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'date_reports.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };
    
    
    

    return (
        <>
            <AdminNavbar />
            <div className=''>
                <div className='dashboard'>
                    {/* <h3 style={{ color: '#11686D' }}>Employee Date Reports</h3> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>


                        {/* <Select

                            style={{ width: '25%', fontFamily: 'var(--font)' }}
                            placeholder="Filter by expiry date"
                            value={filterType}
                            onChange={(value) => setFilterType(value)}
                        >
                            <Option value="withinAMonth">Expiry within a month</Option>
                            <Option value="expired">Expired</Option>
                        </Select> */}
                        <Select
    mode="multiple"
    style={{ width: '35%', fontFamily: 'var(--font)' }}
    placeholder="Select expiry columns"
    value={selectedExpiryColumns}
    onChange={(value) => setSelectedExpiryColumns(value)}
>
    {expiryColumns.map((column) => (
        <Option key={column.dataIndex} value={column.dataIndex}>
            {column.title}
        </Option>
    ))}
</Select>
<div>
    <RangePicker
        style={{ width: '300px', padding: '5px', fontSize: '14px' }} // Adjust the width, padding, and font size
        onChange={(dates, dateStrings) => {
            if (dates) {
                setDateRange([
                    moment(dateStrings[0]),
                    moment(dateStrings[1]),
                ]);
            } else {
                setDateRange([null, null]);
            }
        }}
        format="YYYY-MM-DD"
    />
</div>

                        <Button style={{ backgroundColor: "#6FB555", margin: "1vh 1vw", padding: '0 1vw', height: '23px' }} type="primary" onClick={exportToCSV}>
                            Export to CSV
                        </Button>
                    </div>
                    <div style={{ width: '100%', marginTop: '1rem' }}>
                        <Table

                            pagination={{
                                pageSize: 100,
                                current: currentPage,
                                onChange: (page) => setCurrentPage(page)
                            }}
                            dataSource={filteredEmployees}
                            loading={dataLoaded ? false : !dataLoaded}
                            columns={columns}
                            rowKey="_id"
                            scroll={{
                                x: 'max-content',
                                y: `calc(90vh - 250px)`
                            }}
                        />
                    </div>
                </div>
                {/* <div className='home-logo'>
                    <Link to='/admin' style={{ color: '#11686D', paddingRight: '3vw' }}><HomeFilled /></Link>
                    Date Reports
                </div>
                <div className="smalllogo-image">
                    <img src='/crootive.png' alt='Crootive' className='smalllogo-image' />
                </div> */}
            </div>
        </>
    );
};

export default ManDateReports;



